import {
  ChangeEvent,
  Children,
  cloneElement,
  HTMLAttributes,
  isValidElement,
} from "react"
import styled from "styled-components"

import MuiTabs, { TabsProps } from "@material-ui/core/Tabs"

import { useAppLocation } from "src/hooks/useAppLocation"
import { useTranslate } from "src/i18n/useTranslate"
import { useRouter } from "src/router/useRouter"
import { colorsV2 } from "src/ui/colors"
import { ITabPanel } from "src/ui/Tabs/TabPanel"
import { slugify } from "src/utils/genericUtil"

import Tab, { ITab } from "./Tab"

export interface ITabsProps extends TabsProps {
  labels: {
    label: ITab["label"]
    id: ITab["id"]
    disabled?: boolean
  }[]
  children: React.ReactNode
  hideLabels?: boolean
  usePaths?: boolean
  value?: number
}

export function Tabs({
  labels,
  hideLabels,
  children,
  usePaths,
  value,
  ...rest
}: ITabsProps & HTMLAttributes<React.ReactNode>) {
  const { _t } = useTranslate()
  const { navigate } = useRouter()

  const location = useAppLocation()

  const tabs = labels.map(({ id, label, disabled }, index) => ({
    label,
    hash: `#${slugify(id)}`,
    index,
    disabled,
    path: `${id}`,
  }))

  const activeTab =
    tabs.find((tab) =>
      usePaths
        ? tab.path === location.pathname ||
          location.pathname.startsWith(tab.path)
        : !tab.disabled && tab.hash === location.hash
    ) || tabs?.[value ?? 0]

  const handleChange = (event: ChangeEvent<object>, tabIndex: number) => {
    const activeTab = tabs.find((tab) => tab.index === tabIndex)
    if (activeTab) {
      navigate(usePaths ? activeTab.path : activeTab.hash)
    }
  }

  const childPanelsWithIndex = Children.map(children, (child, index) => {
    if (isValidElement<ITabPanel>(child)) {
      return cloneElement<ITabPanel>(child, {
        index,
        value: activeTab?.index,
      })
    } else {
      throw new Error("Tabs children must be valid elements")
    }
  })

  if (labels.length < 1) {
    return null
  }

  return (
    <>
      <StyledTabs
        value={activeTab?.index}
        onChange={handleChange}
        aria-label="tab panel"
        hidden={hideLabels}
        {...rest}
        variant="scrollable"
      >
        {tabs.map((tab) => (
          <Tab
            key={tab.hash}
            label={_t(tab.label)}
            index={tab.index}
            disabled={!!tab.disabled}
          />
        ))}
      </StyledTabs>
      {childPanelsWithIndex}
    </>
  )
}

const StyledTabs = styled(MuiTabs)`
  border-bottom: 1px solid ${colorsV2.divider};

  & .MuiTabs-indicator {
    height: 2px;
    background-color: ${colorsV2.primary};
  }
`
